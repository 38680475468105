<template>
	<el-card shadow="never" header="联系客服">
		<div style="font-weight:bold ;line-height:50px;">{{userInfo.kefu}}</div>
	</el-card>
</template>

<script>
	import {systemUserUserInfo} from '@/api/api'
	export default {
		name: 'kefu',
		data() {
			return {
				userInfo: {
					username: "未登录",
					name: "",
					gender: 2,
					mobile: "",
					email:"",
					ruleinfo:{
						title:"",
						content:""
					},
					kefu:""
				},
			}
		},
		mounted () {
        	this.getCurrentUserInfo()
        },
		methods: {
			/**
             * 获取当前用户信息
             */
			 getCurrentUserInfo () {
                systemUserUserInfo().then(res=>{
                    if(res.code == 2000) {
                        this.userInfo=res.data.data
                    }
                })
            },
		},

	}
</script>

<style scoped>
	.el-form-item-msg{
		font-size: 12px;
		color: #999;
		clear: both;
		width: 100%;
	}
</style>
