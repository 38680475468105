<template>
    <div :class="{'ly-is-full':isFull}">
        <el-table :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column min-width="130" prop="revuserinfo.email" label="发送对象" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="120" prop="revuserinfo.name" label="姓名"></el-table-column> -->
            <el-table-column min-width="120" prop="orderno" label="报备编号"></el-table-column>
            <el-table-column min-width="120" prop="status_name" label="(变更的)审核状态"></el-table-column>
            <el-table-column min-width="260" prop="content" show-overflow-tooltip label="发送主题"></el-table-column>
            <el-table-column min-width="180" prop="create_datetime" label="提醒时间"></el-table-column>
            <!-- <el-table-column min-width="180" prop="create_datetime" label="发件时间"></el-table-column> -->
            <el-table-column label="操作" fixed="right" width="120">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Detail')">详情</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <AddModuleBaobeiOrderDetail ref="addModuleBaobeiOrderDetailFlag"></AddModuleBaobeiOrderDetail>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight} from "@/utils/util";
    import {baobeiMsgcenter,baobeiMsgcenterDelete,baobeiReport} from '@/api/api'
    import AddModuleBaobeiOrderDetail from "./components/addModuleBaobeiOrderDetail.vue"
    export default {
        components:{
            Pagination,
            AddModuleBaobeiOrderDetail
        },
        name:'bbmessageCenter',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 20,
                },
                pageparm: {
                    page: 1,
                    limit: 20,
                    total: 0
                },
                tableData:[]
            }
        },
        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            //当渲染的文字超出10字后显示省略号
            customEllipsis(value) {
                value = value.replace(/<.*?>/ig,"")       //把v-html的格式标签替换掉
                if(!value) return ""
                if (value.length > 10) {
                    return value.slice(0, 10) + "..."
                }
                return value
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 20
                    }
                    this.pageparm={
                        page: 1,
                        limit: 20,
                        total: 0
                    }
                    this.getData()
                }else if(flag=='detail') {
                    this.getBBDetail(row.orderno)
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 20
                this.getData()
            },
            getBBDetail(orderno) {
                baobeiReport({page:1,limit:1,orderno:orderno}).then(res => {
                     if(res.code ==2000) {
                        this.$refs.addModuleBaobeiOrderDetailFlag.addModuleFn(res.data.data[0],"详情",false)
                     }else{
                        this.$message.warning(res.msg)
                     }
                 })
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                baobeiMsgcenter(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            // 计算搜索栏的高度
            listenResize() {
				this.$nextTick(() => {
				    this.getTheTableHeight()
				})
			},
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight - 10
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }
        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },
        unmounted() {
              // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>
