<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="680px" title="离岗继承" :before-close="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-alert title="1. 原销售所负责的代理商的全部“审核中”的报备申请将作撤销处理。" type="info" style="margin-bottom:10px;" />
                <el-alert title="2. 原销售所负责的代理商，将会变更所属销售为继承销售。" type="info" style="margin-bottom:10px;" />
                <el-form-item label="继承销售人员：" prop="custom_saler">
                    <el-select v-model="formData.jc_saler" placeholder="请选择" clearable style="width: 100%">
                        <el-option
                                v-for="item in salerList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {UsersUsersleaveInherit,UsersUsers} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "auditModuleStaffLeave",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                tempdata:"",
                formData:{
                    id:'',
                    jc_saler:"",
                },
                rules:{
                    jc_saler: [
                        {required: true, message: '请选择',trigger: 'blur'}
                    ]
                },
                salerList:[]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.tempdata = null
                this.formData = {
                    id:'',
                    jc_saler:"",
                }
            },
            auditModuleFn(item) {
                this.dialogVisible=true
                this.formData.id = item.id
                this.tempdata = deepClone(item)
                this.getKHSalerUsers()
            },
            getKHSalerUsers(){
                UsersUsers({page:1,limit:999,is_active:1,onlysaler:1}).then(res=>{
                    if(res.code === 2000){
                        this.salerList = res.data.data
                    }
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        UsersUsersleaveInherit(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

