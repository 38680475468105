<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto" :disabled="loadingTitle=='详情'">
                <el-form-item label="父级：" prop="">
                    <el-select size="default" v-model="formData.parent" placeholder="请选择" clearable style="width: 100%;" filterable @change="handleSelectChange">
                        <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称：" prop="name">
                    <el-input v-model.trim="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="等级：" prop="level">
                    <el-input v-model.trim="formData.level" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" :min="0" :max="99999" style="width: 100%;" placeholder="数字越小越靠前"></el-input-number>
                </el-form-item>
    <!--            <el-form-item label="状态：" prop="status" :disabled="loadingTitle=='详情'" >-->
    <!--                <el-switch-->
    <!--                    v-model="formData.status"-->
    <!--                    active-color="#13ce66"-->
    <!--                    inactive-color="#ff4949">-->
    <!--                </el-switch>-->
    <!--            </el-form-item>-->
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave" :disabled="loadingTitle=='详情'">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {baobeiIndustry,baobeiIndustryAdd,baobeiIndustryEdit} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    export default {
        emits: ['refreshData'],
        name: "addModuleIndustry",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    name:"",
                    sort:1,
                    parent:"",
                    level:1,
                },
                rules:{
                    name: [
                        {required: true, message: '请输入名称',trigger: 'blur'}
                    ],
                },
                typeList:[]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    name:"",
                    sort:1,
                    parent:"",
                    level:1,
                }
                this.$emit('refreshData')
            },
            handleSelectChange(value){
                if(!!value){
                    this.formData.level = 2
                }else{
                    this.formData.level = 1
                }
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.getBaobeiIndustry()
                if(item){
                    if(flag === "新增"){
                        this.formData.level = 2
                        if(item){
                            this.formData.parent=item.id
                        }
                    }else{
                        this.formData = deepClone(item)
                    }
                }
            },
            getBaobeiIndustry(){
                baobeiIndustry({level:1,page:1,limit:999}).then(res=>{
                    if(res.code === 2000){
                        this.typeList = res.data.data
                    }
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            baobeiIndustryEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            baobeiIndustryAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

