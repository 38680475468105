<template>
	<el-card shadow="never" header="代理商">
		<div style="display: flex;justify-content: flex-end;">
			<el-form :inline="true" :model="formInline">
                <el-form-item label="客户名称">
                    <el-input v-model="formInline.agent_name" maxlength="60" clearable placeholder="客户名称" style="width:200px"></el-input>
                </el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search">查询</el-button>
                	<el-button @click="reset">重置</el-button>
				</el-form-item>
            </el-form>
		</div>
		<lyTable ref="lytable" :tableName="tableName" :apiObj="this.$Api.UsersAgentusers" border :hideDo="true" :params="formInline" :column="column" height="auto" paginationLayout="total, prev, pager, next">
		</lyTable>
	</el-card>
</template>

<script>
	import lyTable from '@/components/lyTable/index.vue'
	import lyTableColumn from '@/components/lyTable/column.js'
	export default {
		components: {
			lyTable,
			lyTableColumn
		},
		data() {
			return {
				tableName:"useragenttable",
				column: [
					{
						label: "客户名称",
						prop: "agent_name",
						width: "180",
					},
					{
						label: "联系人",
						prop: "name",
						width: "130",
					},
					{
						label: "手机号",
						prop: "mobile",
						width: "260",
					},
					{
						label: "邮箱",
						prop: "email",
						width: "300",
					},
				],
				formInline:{
                },
			}
		},
		methods:{
			reset(){
				this.formInline = {
				}
				this.search()
			},
			search(){
				this.$refs.lytable.reload(this.formInline)
			},
		}
	}
</script>

<style>
</style>
