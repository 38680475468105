<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="680px" title="审批" :before-close="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="是否同意：" prop="action">
                    <el-radio-group v-model="formData.action" >
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="客户是否由我负责：" prop="is_mycustom" v-if="formData.action == 1 && !!tempdata && !(!!tempdata.is_translate)" class="is-required">
                    <el-radio-group v-model="formData.is_mycustom">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="客户所属销售人员：" prop="custom_saler" class="is-required" v-if="formData.is_mycustom == 2 && formData.action == 1 && !!tempdata && !(!!tempdata.is_translate)">
                    <el-select v-model="formData.custom_saler" placeholder="请选择" filterable clearable style="width: 100%">
                        <el-option
                                v-for="item in salerList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="拒绝理由：" prop="reason" v-if="formData.action===2" class="is-required">
                    <el-input type="textarea" :autosize="{ minRows: 4}" v-model.trim="formData.reason" maxlength="100" show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {baobeiReportSalerAuditApprove,UsersUsers,baobeiGetsalerlist} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "auditModule",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                tempdata:"",
                formData:{
                    id:'',
                    action:1,
                    is_mycustom:1,
                    custom_saler:"",
                    reason:'',
                },
                rules:{
                    action: [
                        {required: true, message: '请选择',trigger: 'blur'}
                    ]
                },
                salerList:[]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    id:'',
                    action:1,
                    is_mycustom:1,
                    custom_saler:"",
                    reason:'',
                }
                this.tempdata = null
            },
            auditModuleFn(item) {
                this.dialogVisible=true
                this.formData.id = item.id
                this.tempdata = deepClone(item)
                this.getKHSalerUsers()
            },
            getKHSalerUsers(){
                baobeiGetsalerlist().then(res=>{
                    if(res.code === 2000){
                        this.salerList = res.data.data
                    }
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.action == 2 && this.formData.reason === ""){
                            this.$message.warning("请填写拒绝理由")
                            return
                        }
                        if(this.formData.action == 1 && this.formData.is_mycustom == 2 && !(!!this.formData.custom_saler)){
                            this.$message.warning("请选择客户所属销售人员")
                            return
                        }
                        this.loadingSave=true
                        baobeiReportSalerAuditApprove(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

