<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="45%" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <div class="ly-title" v-show="loadingTitle!='新增'">
                    基础信息
                </div>
                <el-row :gutter="20" v-show="loadingTitle!='新增'">
                    <el-col :span="12">
                        <el-form-item label="报备编号：" prop="orderno">
                            <el-input v-model="formData.orderno" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="代理商：" prop="agentinfo.agent_name">
                            <el-input v-model="formData.agentinfo.name" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="保存草稿时间：" prop="create_datetime">
                            <el-input v-model="formData.create_datetime" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="报备有效期：" prop="create_datetime">
                            <el-input v-model="formData.create_datetime" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <div class="ly-title">
                    报备信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="客户名称：" prop="c_name">
                            <el-input v-model="formData.c_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目名称：" prop="program_name">
                            <el-input v-model="formData.program_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人：" prop="contacts_name">
                            <el-input v-model="formData.contacts_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号：" prop="contacts_mobile">
                            <el-input v-model="formData.contacts_mobile"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱：" prop="contacts_email">
                            <el-input v-model="formData.contacts_email"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="预算金额：" prop="budget_amount">
                            <div style="display: flex;width: 100%;">
                                <el-input-number v-model="formData.budget_amount" :controls="false" :precision="2" style="width: 100%;"></el-input-number><div>元</div>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属行业：" prop="industry">
                            <el-tree-select v-model="formData.industry" node-key="id" :data="options"
                            check-strictly filterable clearable :render-after-expand="false"
                            :props="{label:'name',value: 'id'}"
                            style="width: 100%" placeholder="请选择" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备产品：" prop="product">
                            <el-select size="default" multiple v-model="formData.product" placeholder="请选择" clearable style="width: 100%">
                                <el-option
                                        v-for="item in productList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注：" prop="remark">
                            <el-input v-model="formData.remark"  type="textarea" maxlength="100" show-word-limit clearable placeholder="多行输入" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <!-- <el-button @click="handleClose" :loading="loadingSave">取消</el-button> -->
                <el-button type="primary" @click="submitData" :loading="loadingSave">提交报备</el-button>
                <el-button @click="handleDraftsSave" :loading="loadingSave">保存</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {baobeiReportAdd,baobeiReportEdit,baobeiDraftsEdit,baobeiDraftsAdd,baobeiProduct,baobeiIndustry,baobeiDraftsSubmitReport} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import XEUtils from "xe-utils";
    import {deepClone} from "@/utils/util";
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "addModuleDraftsOrder",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    orderno:'',
                    c_name:'',
                    agentinfo:{
                        name:""
                    },
                    program_name:'',
                    contacts_name:'',
                    contacts_mobile:"",
                    contacts_email:"",
                    budget_amount:null,
                    industry:"",
                    product:[],
                },
                rules:{
                    c_name: [
                        {required: true, message: '请输入客户名称',trigger: 'blur'}
                    ],
                    program_name: [
                        {required: true, message: '请输入项目名称',trigger: 'blur'}
                    ],
                    contacts_name: [
                        {required: true, message: '请选输入联系人',trigger: 'blur'}
                    ],
                    contacts_mobile: [
                        {required: true, message: '请输入手机号',trigger: 'blur'}
                    ],
                    contacts_email: [
                        {required: true, message: '请输入邮箱',trigger: 'blur'}
                    ],
                    product: [
                        {required: true, message: '请选择产品（可多选）',trigger: 'blur'}
                    ],
                    industry: [
                        {required: true, message: '请选择所属行业',trigger: 'blur'}
                    ],
                    budget_amount: [
                        {required: true, message: '请输入预算金额',trigger: 'blur'}
                    ]
                },
                options:[],
                productList:[]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    orderno:'',
                    c_name:'',
                    program_name:'',
                    contacts_name:'',
                    contacts_mobile:"",
                    contacts_email:"",
                    industry:"",
                    budget_amount:null,
                    product:[],
                }
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.getBaobeiIndustry()
                this.getBaobeiProduct()
                if(item){
                    this.formData = deepClone(item)
                }
            },
            handleDraftsSave(){
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }

                        if(this.formData.id){
                            baobeiDraftsEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            baobeiDraftsAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }

                        baobeiDraftsSubmitReport(param).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })

                    }
                })
            },
            getBaobeiProduct(){
                baobeiProduct({page:1,limit:999}).then(res=>{
                    if(res.code ==2000) {
                        this.productList = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getBaobeiIndustry(){
                baobeiIndustry({page:1,limit:999}).then(res=>{
                    if(res.code ==2000) {
                        this.options = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent' })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },


        }
    }
</script>
<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
</style>

