<template>
	<el-card shadow="never" header="操作记录">
		<lyTable ref="lytable" :tableName="tableName" :apiObj="this.$Api.systemGetOwnerLogs" :column="column" height="auto" paginationLayout="total, prev, pager, next">
		</lyTable>
	</el-card>
</template>

<script>
	import lyTable from '@/components/lyTable/index.vue'
	import lyTableColumn from '@/components/lyTable/column.js'
	export default {
		components: {
			lyTable,
			lyTableColumn
		},
		data() {
			return {
				tableName:"userlogstable",
				column: [
						{
							label: "请求模块",
							prop: "request_modular",
							width: "150",
						},
						{
							label: "请求地址",
							prop: "request_path",
							width: "260",
						},
						{
							label: "请求方法",
							prop: "request_method",
							width: "90",
						},
						{
							label: "IP地址",
							prop: "request_ip",
							width: "130",
						},
						{
							label: "请求浏览器",
							prop: "request_browser",
							width: "260",
							hide: true
						},
						{
							label: "请求数据",
							prop: "request_body",
							width: "230",
							hide: true
						},
						{
							label: "响应码",
							prop: "response_code",
							width: "100",
							hide: true
						},
						{
							label: "返回信息",
							prop: "json_result",
							width: "260",
						},
						{
							label: "操作人",
							prop: "creator_name",
							width: "150",
						},
						{
							label: "创建时间",
							prop: "create_datetime",
							width: "180",
						}
					]
			}
		}
	}
</script>

<style>
</style>
