<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="报备编号：">
                    <el-input v-model.trim="formInline.orderno" maxlength="60"  clearable placeholder="报备编号" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="客户名称：">
                    <el-input v-model.trim="formInline.c_name" maxlength="60"  clearable placeholder="客户名称" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column min-width="160" prop="orderno" label="报备编号"></el-table-column>
            <el-table-column min-width="170" prop="c_name" label="客户名称" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="170" prop="program_name" label="项目名称" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="120" prop="contacts_name"  label="联系人"></el-table-column>
            <el-table-column min-width="130" prop="contacts_mobile"  label="手机号"></el-table-column>
            <el-table-column min-width="120" prop="budget_amount"  label="预算金额"></el-table-column>
            <el-table-column min-width="120" prop="industrynames"  label="所属行业"></el-table-column>
            <el-table-column min-width="160" prop="productnames"  label="报备产品"></el-table-column>
            <el-table-column min-width="160" prop="remark" label="备注"></el-table-column>
            <el-table-column min-width="180" prop="create_datetime" label="保存草稿时间"></el-table-column>
            <el-table-column label="操作" fixed="right" width="130">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <AddModuleDraftsOrder ref="addModuleBaobeiDraftsFlag" @refreshData="getData"></AddModuleDraftsOrder>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {baobeiDrafts,baobeiDraftsDelete} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import AddModuleDraftsOrder from "./components/addModuleDraftsOrder.vue"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            AddModuleDraftsOrder
        },
        name:'draftsManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                defaultImg:require('../../assets/img/avatar.jpg'),
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    name:'',
                    status:'',
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                orderstatics:{
                    totalmoney: 0,
                    totalcount: 0,
                },
                statusList:[
                    {id:0,name:'报备中'},
                    {id:1,name:'保护中'},
                    {id:2,name:'报备失败'},
                    {id:3,name:'已失效'},
                    {id:4,name:'已撤销'},
                ],
                timers:[],
                tableData:[],
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return baobeiDraftsDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='edit') {
                    this.$refs.addModuleBaobeiDraftsFlag.addModuleFn(row,'编辑')
                }
                else if(flag=='delete') {
                    vm.$confirm('确定删除该订单？删除后无法恢复？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        baobeiDraftsDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                baobeiDrafts(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>
