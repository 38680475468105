<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="45%" top="10px" :draggable="false" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto" :disabled="loadingTitle==='详情'" class="form-data">
                <div class="ly-title" v-show="loadingTitle!='新增'">
                    基础信息
                </div>
                <el-row :gutter="20" v-show="loadingTitle!='新增'">
                    <el-col :span="12">
                        <el-form-item label="报备编号：" prop="orderno">
                            <el-input v-model="formData.orderno" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="代理商：" prop="agentinfo.agent_name">
                            <el-input v-model="formData.agentinfo.agent_name" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备人：" prop="agentinfo.name">
                            <el-input v-model="formData.agentinfo.name" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备状态：">
                            <!-- {{ auditList.length>0?auditList[0].status:"" }} -->
                            {{formData.status_name}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备时间：" prop="create_datetime">
                            <el-input v-model="formData.create_datetime" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备生效时间：" prop="valid_datetime">
                            <el-input v-model="formData.valid_datetime" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备失效时间：" prop="invalid_datetime">
                            <el-input v-model="formData.invalid_datetime" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="formData.status2==14 && this.mutitabsstore.identity == 7">
                        <el-form-item label="代理商所属销售：" prop="agentinfo.parent_name">
                            <el-input v-model="formData.agentinfo.parent_name" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    报备信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="客户名称：" prop="c_name">
                            <el-input v-model="formData.c_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目名称：" prop="program_name">
                            <el-input v-model="formData.program_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人：" prop="contacts_name">
                            <el-input v-model="formData.contacts_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号：" prop="contacts_mobile">
                            <el-input v-model="formData.contacts_mobile"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱：" prop="contacts_email">
                            <el-input v-model="formData.contacts_email"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="预算金额：" prop="budget_amount">
                            <div style="display: flex;width: 100%;">
                                <el-input-number v-model="formData.budget_amount" :controls="false" :precision="2" style="width: 100%;"></el-input-number><div>元</div>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属行业：" prop="industry">
                            <el-tree-select v-model="formData.industry" node-key="id" :data="options"
                            check-strictly filterable clearable :render-after-expand="false"
                            :props="{label:'name',value: 'id'}"
                            style="width: 100%" placeholder="请选择" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报备产品：" prop="product">
                            <el-select size="default" multiple v-model="formData.product" placeholder="请选择" clearable style="width: 100%">
                                <el-option
                                        v-for="item in productList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注：" prop="remark">
                            <el-input v-model="formData.remark"  type="textarea" maxlength="100" show-word-limit clearable placeholder="多行输入" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="this.mutitabsstore.identity != 4">
                        <el-form-item label="客户所属销售：" prop="belong_saler_name" class="is-required">
                            <el-input v-model="formData.belong_saler_name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    审批详情<el-text class="auditbtn" v-if="is_audit && formData.status2==14 && this.mutitabsstore.identity == 7" @click="handleAudit">审批</el-text><el-text class="auditbtn" v-if="is_audit && formData.current_approve_user==this.mutitabsstore.userId && this.mutitabsstore.identity == 5 && formData.status == 0" @click="handleAuditSaler">审批</el-text>
                </div>
                <div class="lytimelineContainer" v-if="auditList.length>0">
                    <ul class="lytimeline">
                        <li class="ly-timeline-item" v-for="(item,index) in auditList">
                            <div class="ly-timeline-item__tail"></div>
                            <div class="ly-timeline-item__node">
                                <el-avatar class="ly-timeline-item__icon" :size="30" :src="!!item.avatar?item.avatar:require('@/assets/img/avatar02.jpg')"></el-avatar>
                            </div>
                            <div class="ly-timeline-item__wrapper">
                                <div class="ly-timeline-item__content">
                                    <div style="display: flex;align-items: center;">
                                        <div style="width:300px;">
                                            <span >{{item.name}}&nbsp;&nbsp;·</span><span v-if="item.status == '审核中' || item.status == '复核中'" style="color:#e99d42"> &nbsp;{{item.status}}</span><span v-else-if="item.status == '复核通过' || item.status == '审核通过'" style="color:#81b337"> &nbsp;{{item.status}}</span><span v-else-if="item.status == '审核失败' ||item.status == '平台撤销' ||item.status == '发起者撤销报备'" style="color:red"> &nbsp;{{item.status}}</span><span v-else> &nbsp;{{item.status}}</span>
                                        </div>
                                        <div>
                                            <span v-if="item.is_time" style="color: rgba(102,102,102,1);">{{item.time}}</span>
                                        </div>
                                    </div>
                                    <div class="ly-timeline-item__tips" v-if="!!item.reason">
                                        <span>{{ "原因："+item.reason}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-form>
            <template #footer v-if="!loadingTitle==='详情'">
                <!-- <el-button @click="handleClose" :loading="loadingSave">取消</el-button> -->
                <el-button type="primary" @click="submitData" :loading="loadingSave">提交</el-button>
                <el-button @click="handleDraftsSave" :loading="loadingSave">存草稿</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {baobeiReportAdd,baobeiReportEdit,baobeiDraftsEdit,baobeiDraftsAdd,baobeiProduct,baobeiIndustry,baobeiReportDetail} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import XEUtils from "xe-utils";
    import {deepClone} from "@/utils/util";
    import {useMutitabsStore} from "@/store/mutitabs";
    export default {
        components: {LyDialog},
        emits: ['refreshData','auditReviewer','auditSaler'],
        name: "addModuleBaobeiOrderDetail",
        setup(){
            const mutitabsstore = useMutitabsStore()
            return { mutitabsstore }
        },
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                is_audit:false,
                formData:{
                    orderno:'',
                    c_name:'',
                    agentinfo:{
                        name:""
                    },
                    program_name:'',
                    contacts_name:'',
                    contacts_mobile:"",
                    contacts_email:"",
                    budget_amount:null,
                    industry:"",
                    product:[],
                },
                rules:{
                    c_name: [
                        {required: true, message: '请输入客户名称',trigger: 'blur'}
                    ],
                    program_name: [
                        {required: true, message: '请输入项目名称',trigger: 'blur'}
                    ],
                    contacts_name: [
                        {required: true, message: '请选输入联系人',trigger: 'blur'}
                    ],
                    contacts_mobile: [
                        {required: true, message: '请输入手机号',trigger: 'blur'}
                    ],
                    contacts_email: [
                        {required: true, message: '请输入邮箱',trigger: 'blur'}
                    ],
                    product: [
                        {required: true, message: '请选择产品（可多选）',trigger: 'blur'}
                    ],
                    industry: [
                        {required: true, message: '请选择所属行业',trigger: 'blur'}
                    ],
                    budget_amount: [
                        {required: true, message: '请输入预算金额',trigger: 'blur'}
                    ]
                },
                options:[],
                productList:[],
                auditList:[],
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.is_audit = false
                this.auditList = []
                this.formData = {
                    orderno:'',
                    agentinfo:{
                        name:""
                    },
                    c_name:'',
                    program_name:'',
                    contacts_name:'',
                    contacts_mobile:"",
                    contacts_email:"",
                    industry:"",
                    budget_amount:null,
                    product:[],
                }
            },
            handleAudit(){
                this.$emit('auditReviewer',this.formData)
                this.handleClose()
            },
            handleAuditSaler(){
                this.$emit('auditSaler',this.formData)
                this.handleClose()
            },
            addModuleFn(item,flag,is_audit=true) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.is_audit = is_audit
                this.getBaobeiIndustry()
                this.getBaobeiProduct()
                if(item){
                    this.formData = deepClone(item)
                    this.formData.budget_amount = parseFloat(this.formData.budget_amount)
                }
                this.getBaobeiReportDetail()
            },
            getBaobeiReportDetail(){
                baobeiReportDetail(this.formData.id).then(res=>{
                    if(res.code === 2000){
                        this.auditList = res.data.reverse()
                    }
                })
            },
            handleDraftsSave(){
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }

                        if(this.formData.id){
                            baobeiDraftsEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            baobeiDraftsAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }

                        if(this.formData.id){
                            baobeiReportEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            baobeiReportAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            getBaobeiProduct(){
                baobeiProduct({page:1,limit:999}).then(res=>{
                    if(res.code ==2000) {
                        this.productList = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getBaobeiIndustry(){
                baobeiIndustry({page:1,limit:999}).then(res=>{
                    if(res.code ==2000) {
                        this.options = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent' })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },


        }
    }
</script>
<style scoped>
    .auditbtn{
        margin-left: 20px;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        transition: .1s;
        font-weight: var(--el-button-font-weight);
        -webkit-user-select: none;
        user-select: none;
        vertical-align: middle;
        -webkit-appearance: none;
        background-color: var(--el-color-primary);
        border: var(--el-border);
        border-color: var(--el-color-primary);
        padding: 6px 12px;
        cursor: pointer;
        color:var(--el-color-white);
    }
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .lytimelineContainer{
        padding: 10px;
    }
    .lytimeline{
        margin: 0;
        font-size: var(--el-font-size-base);
        list-style: none;
        padding-left: 90px;
    }
    .ly-timeline-item{
        position: relative;
        height: 55px;
    }
    .ly-timeline-item__tail{
        position: absolute;
        left: 4px;
        height: 100%;
        border-left: 2px solid var(--el-border-color-light);
    }
    .ly-timeline-item__node{
        position: absolute;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -10px;
        width: 30px;
        height: 30px;
    }
    .ly-timeline-item__wrapper{
        position: relative;
        padding-left: 28px;
        top: 10px;
    }
    .lytimeline .ly-timeline-item:last-child .ly-timeline-item__tail {
        display: none;
    }
    .ly-timeline-item__tips{
        margin-top: 6px;
        font-size:12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;/*表示块容器内部子元素沿着垂直方向排列；*/
        -webkit-line-clamp: 2;/*超出两行省略*/
        line-height: 0.9rem;
    }
</style>

