<template>
	<el-card shadow="never" header="个人信息">
		<el-form ref="userInfoForm" :model="userInfo" :rules="userInforules" label-width="120px" style="margin-top:20px;" :disabled="true">
			<el-form-item label="代理商" v-if="!!userInfo.agent_name">
				{{ userInfo.agent_name }}
			</el-form-item>
			<el-form-item label="账号状态">
				{{ "正常" }}
			</el-form-item>
			<!-- <el-form-item label="账号" prop="username">
				<el-input v-model="userInfo.username" disabled></el-input>
				<div class="el-form-item-msg">账号信息用于登录，系统不允许修改</div>
			</el-form-item> -->
			<el-form-item label="姓名" prop="name">
				<el-input v-model="userInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="手机号" prop="mobile">
				<el-input v-model="userInfo.mobile"></el-input>
			</el-form-item>
			<el-form-item label="邮箱" prop="email">
				<el-input v-model="userInfo.email"></el-input>
			</el-form-item>
			<!-- <el-form-item label="性别" prop="gender">
				<el-radio-group v-model="userInfo.gender">
					<el-radio :label="2">男</el-radio>
					<el-radio :label="1">女</el-radio>
					<el-radio :label="0">未知</el-radio>
				  </el-radio-group>
			</el-form-item> -->
			<!-- <el-form-item>
				<el-button type="primary"  @click="updateInfo">保存</el-button>
			</el-form-item> -->
		</el-form>
	</el-card>
</template>

<script>
	import {useMutitabsStore} from "@/store/mutitabs";
	import {systemUserUserInfo,systemUserUserInfoEdit} from '@/api/api'
	export default {
		setup(){
			const mutitabsstore = useMutitabsStore()
			return { mutitabsstore }
		},
		data() {
			return {
				userInfo: {
					username: "未登录",
					name: "",
					gender: 2,
					mobile: "",
					email:"",
				},
				userInforules: {
					name: [
						{ required: true, message: '请输入姓名', trigger: 'blur' }
					],
					mobile: [
						{ pattern: /^1[3|4|5|7|8|9|6]\d{9}$/, message: '请输入正确手机号' }
					]
				},
			}
		},
		mounted () {
        	this.getCurrentUserInfo()
        },
		methods: {
			/**
             * 获取当前用户信息
             */
			 getCurrentUserInfo () {
                systemUserUserInfo().then(res=>{
                    if(res.code == 2000) {
                        this.userInfo=res.data.data
						this.mutitabsstore.refreshUserinfo(this.userInfo)
                    }
                })
            },
			/**
             * 更新用户信息
             */
			 updateInfo () {
              const _self = this
              _self.$refs.userInfoForm.validate((valid) => {
                if (valid) {
                    systemUserUserInfoEdit(_self.userInfo).then(res=>{
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                _self.getCurrentUserInfo()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                } else {
                  // 校验失败
                  // 登录表单校验失败
                  this.$message.error('表单校验失败，请检查')
                }
              })
            },
		},

	}
</script>

<style scoped>
	.el-form-item-msg{
		font-size: 12px;
		color: #999;
		clear: both;
		width: 100%;
	}
</style>
