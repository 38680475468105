<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="名称：">
                    <el-input v-model.trim="formInline.name" maxlength="60"  clearable placeholder="名称" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="handleAdd" icon="Plus" v-show="hasPermission(this.$route.name,'Create')">新增</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" v-loading="loadingPage" style="width: 100%" ref="tableref"
                      @selection-change="handleSelectionChange" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
<!--                <el-table-column type="selection" width="55"></el-table-column>-->
                <!-- <el-table-column type="selection" width="60" align="center"></el-table-column> -->
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="200" prop="name" label="名称"></el-table-column>
                <el-table-column min-width="200" prop="create_datetime" label="添加时间"></el-table-column>
                <el-table-column min-width="100" prop="sort" label="排序"></el-table-column>
                <el-table-column min-width="100" label="状态">
                    <template #default="scope">
                       <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作"  width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="addModule(scope.row)" v-show="hasPermission(this.$route.name,'Create')" v-if="scope.row.level===1">添加</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <AddModuleIndustry ref="addModuleFlag" @refreshData="getData"></AddModuleIndustry>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {baobeiIndustry,baobeiIndustryDelete,baobeiIndustryDisablestatus} from '@/api/api'
    import XEUtils from "xe-utils";
    import AddModuleIndustry from "./components/addModuleIndustry.vue";
    import { lyMixins } from "@/mixins/mixins"
    export default {
        mixins: [lyMixins],
        components:{
            AddModuleIndustry,
            Pagination,
        },
        name:'bbIndustryManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    search:'',
                    page: 1,
                    limit: 100,
                },
                pageparm: {
                    page: 1,
                    limit: 100,
                    total: 0
                },
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                timers:[],
                tableData:[],
                selectData:[]
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            changeStatus(row) {
                let vm = this
                let flat = row.status
                row.status = !row.status
                let temp_is_allow = 1
                vm.$confirm('确定修改状态吗？','提醒',{
                        closeOnClickModal:false,
                        type: "warning"
                    }).then(res=>{
                        flat ? row.status = true : row.status = false
                        if(row.status){
                            temp_is_allow = 1
                        }else{
                            temp_is_allow = 0
                        }
                        baobeiIndustryDisablestatus({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return baobeiIndustryDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            addModule(row) {
                this.$refs.addModuleFlag.addModuleFn(row,'新增')
            },
            handleAdd(){
                this.$refs.addModuleFlag.addModuleFn(null,'新增')
            },
            handleEdit(row,flag) {
                if(flag=='detail') {
                    this.$refs.addModuleFlag.addModuleFn(row,'详情')
                }
                else if(flag=='edit') {
                    this.$refs.addModuleFlag.addModuleFn(row,'编辑')
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        baobeiIndustryDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.getData()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 100
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                baobeiIndustry(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                        //  this.tableData = res.data.data
                         // // 将列表数据转换为树形数据
                         this.tableData = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent', strict: false })
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
        },
        created() {
            this.getData()
        },
    }
</script>



