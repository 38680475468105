<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" title="审批" :before-close="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="是否同意：" prop="action">
                    <el-radio-group v-model="formData.action" >
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="退回理由：" prop="reason" v-if="formData.action===2" class="is-required">
                    <el-input type="textarea" v-model="formData.reason" maxlength="100" show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {baobeiReportReviewerAuditApprove} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "auditModuleReviewer",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                tempdata:"",
                formData:{
                    id:'',
                    action:1,
                    reason:'',
                },
                rules:{
                    action: [
                        {required: true, message: '请选择',trigger: 'blur'}
                    ]
                },
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    id:'',
                    action:1,
                    reason:'',
                }
            },
            auditModuleFn(item) {
                this.dialogVisible=true
                this.formData.id = item.id
                this.tempdata = deepClone(item)
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.action === 2 && this.formData.audit_remarks === ""){
                            this.$message.warning("请填写退回理由")
                            return
                        }
                        this.loadingSave=true
                        baobeiReportReviewerAuditApprove(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

