<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="报备编号：">
                                <el-input v-model.trim="formInline.orderno" maxlength="60"  clearable placeholder="报备编号" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="客户名称：">
                                <el-input v-model.trim="formInline.c_name" maxlength="60"  clearable placeholder="客户名称" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="报备时间：">
                                <el-date-picker
                                        style="width:100%"
                                        v-model="timers"
                                        type="daterange"
                                        :shortcuts="shortcuts"
                                        @change="timeChange"
                                        value-format="YYYY-MM-DD"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="报备状态：">
                                <el-select v-model="formInline.status_m" multiple placeholder="请选择" clearable @change="search" style="width:100%">
                                    <el-option
                                            v-for="item in statusList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div style="width: 100%;" v-if="showOtherSearch">
                    <el-row :gutter="20">
                        <el-col :span="6" v-if="this.mutitabsstore.identity != 4">
                            <el-form-item label="代理商：">
                                <el-input v-model.trim="formInline.agent_name" maxlength="60"  clearable placeholder="代理商" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="this.mutitabsstore.identity != 4">
                            <el-form-item label="代理商所属销售：">
                                <el-input v-model.trim="formInline.parent_name" maxlength="60"  clearable placeholder="代理商所属销售" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="this.mutitabsstore.identity != 4">
                            <el-form-item label="客户所属销售：">
                                <el-input v-model.trim="formInline.belong_saler_name" maxlength="60"  clearable placeholder="客户所属销售" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="this.mutitabsstore.identity != 4">
                            <el-form-item label="当前审批人：">
                                <el-input v-model.trim="formInline.current_approve_username" maxlength="60"  clearable placeholder="当前审批人" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="this.mutitabsstore.identity == 4">
                            <el-form-item label="所属行业：">
                                <el-input v-model.trim="formInline.industry_name" maxlength="60"  clearable placeholder="所属行业" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="this.mutitabsstore.identity == 4">
                            <el-form-item label="报备产品：">
                                <el-input v-model.trim="formInline.product" maxlength="60"  clearable placeholder="报备产品" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div style="display:flex;width:100%;">
                    <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                    <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                    <el-form-item label=""><el-button  @click="handleEdit('','add')" type="primary" icon="Plus" v-show="hasPermission(this.$route.name,'Create')">新增报备</el-button></el-form-item>
                    <el-form-item label=""><el-button  @click="handleDelete" type="danger" :disabled="multiple" v-show="hasPermission(this.$route.name,'SystemCancel')">批量平台撤销</el-button></el-form-item>
                    <el-form-item label="" v-show="hasPermission(this.$route.name,'Export')"><el-button @click="exportData" type="primary" icon="Download">导出</el-button></el-form-item>
                    <el-form-item label="" @click="clickMore" v-if="!showOtherSearch">
                        <span class="lysearchmore">展开
                            <el-icon><ArrowDown /></el-icon>
                        </span>
                    </el-form-item>
                    <el-form-item label="" @click="clickMore" v-if="showOtherSearch">
                        <span class="lysearchmore">收起
                            <el-icon><ArrowUp /></el-icon>
                        </span>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column width="130" prop="orderno" label="报备编号"></el-table-column>
            <el-table-column min-width="90" label="报备状态">
                <template #default="scope">
                    <span>{{statusList.filter(item=>item.id==scope.row.status)[0].name}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="150" prop="agentinfo.agent_name" label="代理商" show-overflow-tooltip v-if="this.mutitabsstore.identity != 4"></el-table-column> -->
            <el-table-column min-width="150" prop="agentinfo.agent_name" label="代理商" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="100" prop="agentinfo.name" label="报备人" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="170" prop="c_name" label="客户名称" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="170" prop="program_name" label="项目名称" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="150" prop="agentinfo.parent_name" label="代理商所属销售" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="150" prop="belong_saler_name" label="客户所属销售" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="120" prop="budget_amount"  label="预算金额"></el-table-column>
            <el-table-column min-width="120" prop="industrynames" label="所属行业"></el-table-column>
            <el-table-column min-width="160" prop="productnames"  label="报备产品"></el-table-column>
            <el-table-column min-width="170" prop="create_datetime" label="报备时间"></el-table-column>
           <el-table-column min-width="160" prop="current_approve_username" label="当前审批人"></el-table-column>
           <el-table-column min-width="170" prop="valid_datetime" label="报备生效时间"></el-table-column>
           <el-table-column min-width="170" prop="invalid_datetime" label="报备失效时间"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Detail')">详情</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'chexiao')" v-show="hasPermission(this.$route.name,'CancelReport') && scope.row.status == 0">撤销报备</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'renew')" v-show="this.mutitabsstore.identity == 4 && (scope.row.status == 4 ||scope.row.status == 3||scope.row.status == 2)">重新报备</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'systemchexiao')" v-show="hasPermission(this.$route.name,'SystemCancel') && scope.row.status == 0">平台撤销</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <AddModuleBaobeiOrder ref="addModuleBaobeiOrderFlag" @refreshData="getData"></AddModuleBaobeiOrder>
        <AddModuleBaobeiOrderDetail ref="addModuleBaobeiOrderDetailFlag" @refreshData="getData"></AddModuleBaobeiOrderDetail>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,lyParseParam2UrlParam} from "@/utils/util";
    import {baobeiReport,baobeiReportDelete,baobeiReportCancelReport,baobeiReportSystemCancelReport,baobeiReportExport} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import AddModuleBaobeiOrder from "./components/addModuleBaobeiOrder.vue"
    import AddModuleBaobeiOrderDetail from "./components/addModuleBaobeiOrderDetail.vue"
    import {useMutitabsStore} from "@/store/mutitabs";
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            AddModuleBaobeiOrder,
            AddModuleBaobeiOrderDetail
        },
        name:'baobeiOrderManage',
        setup(){
            const mutitabsstore = useMutitabsStore()
            return { mutitabsstore }
        },
        data() {
            return {
                isFull:false,
                loadingPage:false,
                showOtherSearch:true,//隐藏过长的搜索条件
                defaultImg:require('../../assets/img/avatar.jpg'),
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    status_m:[],
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                orderstatics:{
                    totalmoney: 0,
                    totalcount: 0,
                },
                statusList:[
                    {id:0,name:'报备中'},
                    {id:1,name:'保护中'},
                    {id:2,name:'报备失败'},
                    {id:3,name:'已失效'},
                    {id:4,name:'已撤销'},
                ],
                timers:[],
                tableData:[],
                shortcuts:[
                    {
                        text: '今',
                        value: () => {
                        const end = new Date()
                        const start = new Date()
                        return [start, end]
                        },
                    },
                    {
                        text: '昨',
                        value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setDate(new Date().getDate() - 1)
                        return [start, start]
                        },
                    },
                    {
                        text: '本周',
                        value: () => {
                        // 获取今天
                        const end = new Date();
                        // 获取当前周的第一天
                        const start = new Date(
                            end.getFullYear(),
                            end.getMonth(),
                            end.getDate() - ((end.getDay() + 6) % 7)
                        );
                        start.setTime(start.getTime());
                        return [start, end]
                        },
                    },
                    {
                        text: '本月',
                        value: () => {
                        const end = new Date();
                        // 获取当前月的第一天
                        const start = new Date(end.getFullYear(), end.getMonth(), 1);
                        start.setTime(start.getTime());
                        return [start, end]
                        },
                    },
                ],
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            clickMore(){
                this.showOtherSearch = !this.showOtherSearch
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('报备单正在审核中，确定要撤销报备单吗？', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return baobeiReportSystemCancelReport({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='detail') {
                    this.$refs.addModuleBaobeiOrderDetailFlag.addModuleFn(row,"详情",false)
                }
                else if(flag=='renew') {
                    this.$refs.addModuleBaobeiOrderFlag.addModuleFn(row,"新增")
                }
                else if(flag=='add') {
                    this.$refs.addModuleBaobeiOrderFlag.addModuleFn(null,"新增")
                }else if(flag=='chexiao') {
                    vm.$confirm('报备单正在审核中，确定要撤销报备单吗？',"提示",{
                        type:'warning',
                        closeOnClickModal:false
                    }).then(res=>{
                        baobeiReportCancelReport({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }else if(flag=='systemchexiao') {
                    vm.$confirm('报备单正在审核中，确定要撤销报备单吗？',"提示",{
                        type:'warning',
                        closeOnClickModal:false
                    }).then(res=>{
                        baobeiReportSystemCancelReport({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=='delete') {
                    vm.$confirm('确定删除该条数据吗？删除后无法恢复？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        baobeiReportDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        status_m:[],
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                let param = {
                    ...this.formInline
                }
                param.status_m  = JSON.stringify(param.status_m)
                baobeiReport(param).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            //excel文件流下载
            downloadExcelBlob(res) {
                let fileName = new Date().getTime() +".xlsx"
                let dispositionStr = res.headers["content-disposition"];
                if (dispositionStr == null || dispositionStr === "") {

                }else{
                    // 获取文件名
                    let dispositionArr = dispositionStr.split(";");
                    fileName = decodeURIComponent(dispositionArr[1]);
                    fileName = fileName.split("=")[1];
                }
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                let href = window.URL.createObjectURL(blob); //下载链接
                let link = document.createElement("a")
                link.href = href
                link.download = fileName //下载后文件名
                document.body.appendChild(link);
                link.click(); //点击下载
                document.body.removeChild(link); //下载完成移除元素
                window.URL.revokeObjectURL(href);  //释放blob对象
                this.$message.success('导出成功')
            },
            //导出
            exportData(row) {
                let params = {}
                let urlparams = lyParseParam2UrlParam(this.formInline)
                urlparams = !!urlparams?"?"+urlparams:''
                if(this.ids.length>0){
                    params['ids'] = this.ids
                }
                this.loadingPage = true
                baobeiReportExport(urlparams,params).then(res => {
                    this.loadingPage = false
                    this.downloadExcelBlob(res)
                })
            }
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
    
</style>
