<template>
	<el-container class="lypageUser">
		<el-aside style="width: 300px;">
			<el-container>
				<el-header style="height: auto;display: block;">
					<div class="userinfotop">
						<el-avatar :size="70" :src="!!this.mutitabsstore.avatar?this.mutitabsstore.avatar:require('@/assets/img/avatar02.jpg')"></el-avatar>
						<h2>{{ this.mutitabsstore.userName }}</h2>
						<p v-if="!!this.mutitabsstore.parentinfo"><el-tag type="info" dark round size="large" disable-transitions>{{ this.mutitabsstore.parentinfo }}</el-tag></p>
					</div>
				</el-header>
				<el-menu class="menu" :default-active="page">
					<el-menu-item-group v-for="group in menu" :key="group.groupName" :title="group.groupName">
						<el-menu-item v-for="item in group.list" :key="item.component" :index="item.component" @click="openPage">
							<el-icon v-if="item.icon"><component :is="item.icon"/></el-icon>
							<template #title>
								<span>{{item.title}}</span>
							</template>
						</el-menu-item>
					</el-menu-item-group>
				</el-menu>
			</el-container>
		</el-aside>
		<el-main class="lyusercontent">
			<Suspense>
				<template #default>
					<component :is="page"/>
				</template>
				<template #fallback>
					<el-skeleton :rows="3" />
				</template>
			</Suspense>
		</el-main>
	</el-container>
</template>

<script>

	import {useMutitabsStore} from "@/store/mutitabs";
	import { defineAsyncComponent } from 'vue'

	export default {
		name: 'userCenter',
		components: {
			account: defineAsyncComponent(() => import('./components/account')),
			password: defineAsyncComponent(() => import('./components/password')),
			agentlist: defineAsyncComponent(() => import('./components/agentlist')),
			userule: defineAsyncComponent(() => import('./components/userule')),
			kefu: defineAsyncComponent(() => import('./components/kefu')),
		},
		setup(){
			const mutitabsstore = useMutitabsStore()
			return { mutitabsstore }
		},
		mounted(){
			this.$nextTick(()=>{
				if(this.mutitabsstore.identity == 5 && JSON.parse(this.mutitabsstore.roleIds).includes("saler")){
					this.menu.push({
							groupName: "数据管理",
							list: [
								{
									icon: "clock",
									title: "代理商",
									component: "agentlist"
								}
							]
						})
				}
			})
			
		},
		data() {
			return {
				menu: [
					{
						groupName: "基本设置",
						list: [
							{
								icon: "postcard",
								title: "账号信息",
								component: "account"
							},
							{
								icon: "lock",
								title: "密码",
								component: "password"
							},
						]
					},
					{
						groupName: "平台信息",
						list: [
							{
								icon: "InfoFilled",
								title: "使用规则",
								component: "userule"
							},
							{
								icon: "Service",
								title: "联系客服",
								component: "kefu"
							},
						]
					},
				],
				page: "account"
			}
		},
		methods: {
			openPage(item){
				this.page = item.index
			},
		},
	}
</script>

<style scoped>
	.lypageUser{
		margin-bottom: 70px;
	}
	.userinfotop {
		text-align: center;
		margin-top: 15px;
	}
	.userinfotop h2 {
		font-size: 18px;
		margin-top: 5px;
	}
	.userinfotop p {
		margin: 8px 0 10px 0;
	}
	.lypageUser .el-aside{
		background: var(--el-bg-color);
		border-radius: 4px;
		border: 1px solid var(--el-border-color-light);
		transition: 0.2s;
		/*height: 580px;*/
	}
	.lypageUser .menu .el-menu-item{
		font-size: 13px;
		--el-menu-item-height:50px;
	}
	.lypageUser .menu .el-menu-item-group {
		border-top: 1px solid var(--el-border-color-light);
	}
	.lypageUser .menu .el-menu-item-group:first-child{
		border: 0;
	}
	.lypageUser .menu{
		border: 0;
	}
	.lyusercontent{
		padding: 0 0 0 10px;
	}
	@media (max-width: 992px){
		.lypageUser{
			display: block;
			height: auto;
		}
		.lypageUser .el-aside {
			width: 100%!important;
			border-bottom: 1px solid var(--el-border-color-light)!important;
		}
		.lyusercontent{
			padding: 10px 0 0 0;
		}
	}

</style>