<!--
 * @Descripttion: 客服电话
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023-09-13
-->
<template>
	<div>
		<el-row :gutter="20">
            <el-col :span="5"><el-input type="number" v-model="phone.phone1" style="width: 100%;" :disabled="true" v-limitPositiveNumber></el-input></el-col>
            <el-col :span="1">-</el-col>
			<el-col :span="5"><el-input type="number" v-model="phone.phone2" style="width: 100%;" v-limitPositiveNumber></el-input></el-col>
            <el-col :span="1">-</el-col>
			<el-col :span="5"><el-input type="number" v-model="phone.phone3" style="width: 100%;" v-limitPositiveNumber></el-input></el-col>
		</el-row>
	</div>
</template>

<script setup>
    import {ref, onMounted, watch,computed} from 'vue'

	const props = defineProps({
        modelValue: { 
            type: Object, 
            default: ()=>{ 
                return {
                    phone1:400, 
                    phone2:111,
                    phone3:2222
                }
            }
        }
    })

	const emits = defineEmits(['update:modelValue'])

	const phone = computed({
		get() {
			return props.modelValue
		},
		set(value) {
			emits('update:modelValue', value)
		}
    })

	defineExpose({

	})
</script>

<style scoped>

</style>